// PrimeVue package must be installed per product

// PrimeVue
import './themes/aura/aura-light/green/theme.scss';
import PrimeVue from 'primevue/config';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import InlineMessage from 'primevue/inlinemessage';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import TieredMenu from 'primevue/tieredmenu';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import PanelMenu from 'primevue/panelmenu';

// font family
import './fonts/font-faces.css';

// directives and services
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

// custom components
import SwBlockUI from '../components-vue3/general/BlockUI.vue';
import SwDialogButtonWrapper from '../components-vue3/general/DialogButtonWrapper.vue';
import SwCollapsible from '../components-vue3/general/Collapsible.vue';
import SwCol from '../components-vue3/layout/Col.vue';
import SwFormGroup from '../components-vue3/form/FormGroup.vue';
import SwRow from '../components-vue3/layout/Row.vue';
import SwSimpleDropdown from '../components-vue3/SimpleDropdown.vue';

// custom directives
import SidebarDirective from './directives/sidebar';

// custom services
import PromiseConfirm from './services/promiseConfirm';

export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, options) => {
    // add primevue
    app.use(PrimeVue, { ripple: true })

      // add components, this can be automated with vite but we use webpack... for now...
      // PrimeVue Services
      .use(ConfirmationService)
      .use(ToastService)
      // PrimeVue components
      .component('PvAccordion', Accordion)
      .component('PvAccordionTab', AccordionTab)
      .component('PvAutoComplete', AutoComplete)
      .component('PvBadge', Badge)
      .component('PvBlockUI', BlockUI)
      .component('PvBreadcrumb', Breadcrumb)
      .component('PvButton', Button)
      .component('PvButtonGroup', ButtonGroup)
      .component('PvCalendar', Calendar)
      .component('PvCard', Card)
      .component('PvCheckbox', Checkbox)
      .component('PvChip', Chip)
      .component('PvChips', Chips)
      .component('PvColumn', Column)
      .component('PvConfirmDialog', ConfirmDialog)
      .component('PvConfirmPopup', ConfirmPopup)
      .component('PvDataTable', DataTable)
      .component('PvDataView', DataView)
      .component('PvDialog', Dialog)
      .component('PvDivider', Divider)
      .component('PvDropdown', Dropdown)
      .component('PvFieldset', Fieldset)
      .component('PvFileUpload', FileUpload)
      .component('PvIconField', IconField)
      .component('PvInlineMessage', InlineMessage)
      .component('PvInputGroup', InputGroup)
      .component('PvInputGroupAddon', InputGroupAddon)
      .component('PvInputIcon', InputIcon)
      .component('PvInputMask', InputMask)
      .component('PvInputNumber', InputNumber)
      .component('PvInputSwitch', InputSwitch)
      .component('PvInputText', InputText)
      .component('PvListbox', Listbox)
      .component('PvMegaMenu', MegaMenu)
      .component('PvMenu', Menu)
      .component('PvMessage', Message)
      .component('PvMultiSelect', MultiSelect)
      .component('PvOverlayPanel', OverlayPanel)
      .component('PvPaginator', Paginator)
      .component('PvPanel', Panel)
      .component('PvPanelMenu', PanelMenu)
      .component('PvPassword', Password)
      .component('PvProgressBar', ProgressBar)
      .component('PvProgressSpinner', ProgressSpinner)
      .component('PvRadioButton', RadioButton)
      .component('PvScrollPanel', ScrollPanel)
      .component('PvSelectButton', SelectButton)
      .component('PvSidebar', Sidebar)
      .component('PvSlider', Slider)
      .component('PvSplitter', Splitter)
      .component('PvSplitterPanel', SplitterPanel)
      .component('PvTag', Tag)
      .component('PvTabMenu', TabMenu)
      .component('PvTabPanel', TabPanel)
      .component('PvTabView', TabView)
      .component('PvTextarea', Textarea)
      .component('PvTieredMenu', TieredMenu)
      .component('PvToast', Toast)
      .component('PvToggleButton', ToggleButton)
      // PrimeVue directives
      .directive('badge', BadgeDirective)
      .directive('ripple', Ripple)
      .directive('tooltip', Tooltip)
      // supwiz-made components
      .component('SwBlockUI', SwBlockUI)
      .component('SwCol', SwCol)
      .component('SwCollapsible', SwCollapsible)
      .component('SwFormGroup', SwFormGroup)
      .component('SwRow', SwRow)
      .component('SwSimpleDropdown', SwSimpleDropdown)
      .component('SwDialogButtonWrapper', SwDialogButtonWrapper)
      // supwiz-made directives
      .directive('sidebar', SidebarDirective)
      // supwiz-made services
      .use(PromiseConfirm);
  },
};
